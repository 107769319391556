import { restapilink, baseurl, baseurlassets } from './Autoload'; 
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { NavLink } from 'react-router-dom';
const $ = require("jquery");   

function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Blog() {
  const [datablog, setdatablog] = useState([]);
  const [datablogstatus, setdatablogstatus] = useState();
  const [countdatablog, setcountdatablog] = useState(0);
  const [countalldatablog, setcountalldatablog] = useState(0);
  const [inputsearch, setinputsearch] = useState('');
  const [dataperpage, setdataperpage] = useState(6);
  const [kategori, setkategori] = useState('');

  const getdatablog = async () => { 
    try {
      let response = await axios.post(restapilink+`api/data/datablog`, {
        uri: '',
        inputsearch: inputsearch,
        kategori: kategori,
        dataperpage: dataperpage
      }); 
      setdatablog(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  
  const getcountdatablog = () => { 
    axios.post(`${restapilink}/api/data/countdatablog`, {
      uri: '',
      inputsearch: inputsearch,
      kategori: kategori,
      dataperpage: dataperpage
    }).then(res => {
      setcountdatablog(res.data);  
    }).catch(err => {
      setcountdatablog(0);
    });
  }
  const getcountalldatablog = () => { 
    axios.post(`${restapilink}/api/data/countalldatablog`, {
      uri: '',
      inputsearch: inputsearch,
      kategori: kategori,
      dataperpage: dataperpage
    }).then(res => {
      setcountalldatablog(res.data);  
    }).catch(err => {
      setcountalldatablog(0);
    });
  }
 
  const getdatablogstatus = async () => {
    try {
      let response = await axios.post(restapilink+`api/data/datablogstatus`, {
        inputsearch: inputsearch,
        kategori: kategori,
        dataperpage: dataperpage
      }); 
      setdatablogstatus(response.data);
    }
    catch(e) {console.log(e.message);}
  }

  const searchHandler = (e) => {
    e.preventDefault();  
    setinputsearch(e.target.value);
  }
  $(".box_search input").on('focus',function(){
    $(".box_search").css('border','2px solid #007bff');
  }) 
  $(".box_search input").on('focusout',function(){
    $(".box_search").css('border','2px solid #e5e5e5');
  });

  const changecategory = (e) => { 
    let kode = e.target.getAttribute('kode') 
    let toclass = e.target.getAttribute('toclass') 
    $(".menu-category").removeClass(" active ")    
    $("."+toclass).addClass(" active ")
    setkategori(kode);  
    setdataperpage(6) 
  }
  
  useEffect(() => {  
    getdatablogstatus();  
    getdatablog(); 
    getcountdatablog();  
    getcountalldatablog();  
    setTimeout(function(){
      if(countdatablog!==0 && countalldatablog!==0) {
        if(countdatablog == countalldatablog) {
          $(".load_more").css('display','none');  
        }
        else {
          $(".load_more").css('display','inline-block'); 
        }
      }
      else {
        $(".load_more").css('display','none'); 
      }
    }, 500);
    setTimeout(function(){
      $(".menu").removeClass(' active ');
      $(".menu-blog").addClass(' active ');
      $(".menu-mobile").removeClass(' active ');
      $(".menu-mobile-blog").addClass(' active ');
    }, 100);
  }, [inputsearch, dataperpage, kategori, countdatablog, countalldatablog]);

  const loadmoredata = (e) => {
    let newdataperpage = dataperpage + 6;
    setdataperpage(newdataperpage)
    getdatablogstatus();  
    getdatablog(); 
    getcountdatablog();  
    getcountalldatablog(); 
  }

  return(
    <div className="bodypage animated fadeIn blogpage">
    <title>Blog | Arif Siddik Muharam | Web Developer</title>
    <meta name="title" content="Blog | Arif Siddik Muharam | Web Developer" />
    <meta itemprop="name" content="Blog | Arif Siddik Muharam | Web Developer" />
    <meta property="og:title" content="Blog | Arif Siddik Muharam | Web Developer" />
    <meta name="twitter:title" content="Blog | Arif Siddik Muharam | Web Developer" />
      <section id="latestproject">
        <div className="container">
            <h1 className="content_title">Blog</h1>
            <p>
              Sometimes I write articles about my personal topics, technology, tutorials, and others that I know, because I find it quite fun to share even though I have little knowledge.
            </p>
            <div className="box_search">  
                <i className="fa fa-search"></i> 
                <input type="text" className="input_search" placeholder="Search keyword..." onChange={searchHandler} value={inputsearch} /> 
            </div> 
            <div className="list_category_link"> 
              <a className="menu-category category-all active" href="javascript:void(0);" onClick={changecategory} toclass="category-all" kode="all">All</a> 
              <a className="menu-category category-personal" href="javascript:void(0);" onClick={changecategory} toclass="category-personal" kode="personal">Personal</a>
              <a className="menu-category category-tutorial" href="javascript:void(0);" onClick={changecategory} toclass="category-tutorial" kode="tutorial">Tutorial</a>
              <a className="menu-category category-programming" href="javascript:void(0);" onClick={changecategory} toclass="category-programming" kode="programming">Programming</a>
            </div>
            <hr />
            <h4 className='font-semibold'>{datablogstatus}</h4>
            <div className="row justify-content-center row-blog">
            {
              Array.isArray(datablog) ?
              datablog.map((dblog, i) => {
                var blogthumbnail = baseurlassets+"image/blog/thumbnail/"+dblog.blog_thumbnail;  
                // var bloguri = baseurl + 'blog/' + dblog.blog_uri + '/';
                var bloguri = dblog.blog_uri + '/';
                var blogjudul = ucfirst(dblog.blog_judul);  
                var blogpreview = dblog.blog_link !== "" ? dblog.blog_link : "javascript:void(0);"; 
                var blogpreviewtarget = dblog.blog_link !== "" ? "_blank" : "";
                var category = "";
                var pecahcategory = dblog.blog_category.split(",");
                for (let i = 0; i < pecahcategory.length; i++) {
                    var cat = pecahcategory[i].trim();
                    if(cat!=="") { 
                        category += `<span class="badge bgcolor3"><i class="fa fa-tag"></i> `+ucwords(cat)+`</span>&nbsp;`;
                    }
                }
                return ( 
                  <div className="card col-lg-4 mb-1" key={i}>
                    <div className="image">
                      <img src={blogthumbnail} />
                    </div>
                    <div className="card-body">
                    <NavLink to={bloguri} className="card-title">{blogjudul}</NavLink>
                    <br />
                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(category)}}></span>
                    </div> 
                  </div>
                )
              })
              : <div className='alert text-center alert-danger d-block'>Oops, data not found.</div>
            }
            </div>
            <center>
                <a href="javascript:void(0);" limit="6" category="all" className="load_more button-blue-2 mt-4" onClick={loadmoredata}>
                  Load More
                </a>
            </center>
        </div>
      </section>
    </div>
  )
}
export default Blog;