import React, { useEffect, useState } from 'react';
import { restapilink, baseurl, baseurlassets } from './Autoload'; 
import axios from 'axios';
import DOMPurify from 'dompurify';
const $ = require("jquery");  

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Products(props) {
  const [datashop, setdatashop] = useState([]);
  const [datashopstatus, setdatashopstatus] = useState();
  const [countalldatashop, setcountalldatashop] = useState(0);
  const [countdatashop, setcountdatashop] = useState(0);
  const [inputsearch, setinputsearch] = useState('');
  const [dataperpage, setdataperpage] = useState(6);
  const [kategori, setkategori] = useState('');

  const getdatashop = async () => { 
    try {
      let response = await axios.post(restapilink+`api/data/datashop`, {
        inputsearch: inputsearch,
        kategori: kategori,
        dataperpage: dataperpage
      }); 
      setdatashop(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  const getcountdatashop = () => { 
    axios.post(`${restapilink}/api/data/countdatashop`, {
      inputsearch: inputsearch,
      kategori: kategori,
      dataperpage: dataperpage
    }).then(res => {
      setcountalldatashop(res.data);  
    }).catch(err => {
      setcountalldatashop(0);
    });
  }
  const getcountalldatashop = () => { 
    axios.post(`${restapilink}/api/data/countalldatashop`, {
      inputsearch: inputsearch,
      kategori: kategori,
      dataperpage: dataperpage
    }).then(res => {
      setcountdatashop(res.data);  
    }).catch(err => {
      setcountdatashop(0);
    });
  }
  const getdatashopstatus = async () => {
    try {
      let response = await axios.post(restapilink+`api/data/datashopstatus`, {
        inputsearch: inputsearch,
        kategori: kategori,
        dataperpage: dataperpage
      }); 
      setdatashopstatus(response.data);
    }
    catch(e) {console.log(e.message);}
  }

  const searchHandler = (e) => {
    e.preventDefault();  
    setinputsearch(e.target.value);
  }

  $(".box_search input").on('focus',function(){
    $(".box_search").css('border','2px solid #007bff');
  }) 
  $(".box_search input").on('focusout',function(){
    $(".box_search").css('border','2px solid #e5e5e5');
  })

  const changecategory = (e) => { 
    let kode = e.target.getAttribute('kode') 
    let toclass = e.target.getAttribute('toclass') 
    $(".menu-category").removeClass(" active ")    
    $("."+toclass).addClass(" active ")
    setkategori(kode);  
    setdataperpage(6) 
  }
  
  useEffect(() => {  
    getdatashopstatus();  
    getdatashop();  
    getcountdatashop();  
    getcountalldatashop();  
    setTimeout(function(){
      // console.log(countdatashop)
      // console.log(countalldatashop)
      if(countdatashop!==0 && countalldatashop!==0) {
        if(countdatashop == countalldatashop) {
          $(".load_more").css('display','none');
        }
        else {
          $(".load_more").css('display','inline-block'); 
        }
      }
      else {
        $(".load_more").css('display','none'); 
      }
    }, 500);
    setTimeout(function(){
      $(".menu").removeClass(' active ');
      $(".menu-shop").addClass(' active ');
      $(".menu-mobile").removeClass(' active ');
      $(".menu-mobile-shop").addClass(' active ');
    }, 100); 
  }, [inputsearch, dataperpage, kategori, getcountdatashop, getcountalldatashop]);
  const loadmoredata = (e) => {
    let newdataperpage = dataperpage + 6;
    setdataperpage(newdataperpage)
    getdatashop(); 
    getdatashopstatus();  
    getcountdatashop(); 
    getcountalldatashop(); 
  }
  return(
    <div className="bodypage animated fadeIn shoppage">
    <title>Produk Saya | Arif Siddik Muharam | Web Developer</title>
    <meta name="title" content="Produk Saya | Arif Siddik Muharam | Web Developer" />
    <meta itemprop="name" content="Produk Saya | Arif Siddik Muharam | Web Developer" />
    <meta property="og:title" content="Produk Saya | Arif Siddik Muharam | Web Developer" />
    <meta name="twitter:title" content="Produk Saya | Arif Siddik Muharam | Web Developer" />
      <section id="latestproject">
        <div className="container">
            <h1 className="content_title">Products</h1>
            <p>
                Sometimes I make digital projects that are general in nature and I think they are suitable for sale, if you are interested in your work, or personal assignments, please contact me.
            </p>
            <div className="box_search">
                <i className="fa fa-search"></i>
                <input type="text" className="input_search" placeholder="Search keyword..." onChange={searchHandler} value={inputsearch} /> 
            </div>
            <div className="list_category_link">
              <a className="menu-category category-all active" href="javascript:void(0);" onClick={changecategory} toclass="category-all" kode="all">All</a>
              <a className="menu-category category-web" href="javascript:void(0);" onClick={changecategory} toclass="category-web" kode="Web">Web</a>
              <a className="menu-category category-sourcecode" href="javascript:void(0);" onClick={changecategory} toclass="category-sourcecode" kode="Source Code">Source Code</a>
              <a className="menu-category category-tools" href="javascript:void(0);" onClick={changecategory} toclass="category-tools" kode="Tools">Tools</a>
            </div>
            <hr />
            <h4 class="font-semibold">{datashopstatus}</h4>
            <div className="row justify-content-center row-shop">
            {
              Array.isArray(datashop) ?
              datashop.map((dshop, i) => {
                var shopjudul = dshop.shop_judul.trim();
                var shopdesc = dshop.shop_desc.replace(/<[^>]*>?/gm, '');
                var shop_thumbnail = baseurlassets+"image/shop/thumbnail/"+dshop.shop_thumbnail;  
                var shoplink = dshop.shop_link !== "" ? dshop.shop_link : "javascript:void(0);"; 
                var shoplinktarget = dshop.shop_link !== "" ? "_blank" : "";
                var shopinterested = `https://api.whatsapp.com/send?phone=6289514392694&text=Hallo Mas Arif👋🏻, %0aSaya berminat membeli *`+shopjudul+`*, apakah produk ini masih tersedia dan bisa saya bayarkan?`;

                var category = "";
                var pecahcategory = dshop.shop_category.split(",");
                for (let i = 0; i < pecahcategory.length; i++) {
                    var cat = pecahcategory[i].trim();
                    if(cat!=="") {
                        category += `<span class="badge bgcolor3"><i class="fa fa-tag"></i> `+ucwords(cat)+`</span>&nbsp;`;
                    }
                }
                return ( 
                  <div className="card col-lg-4 mb-1" key={i}>
                    <div className="image">
                        <img src={shop_thumbnail} />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{shopjudul}</h5>
                      <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(category)}}></span>
                      <br />
                      <span className="badge bgcolor3"><i className="fa fa-dollar-sign"></i> {dshop.shop_sold} Sold</span>
                      <p className="card-text">{shopdesc}</p>
                    </div>
                    <div className="card-footer ">
                      <h4 className="card-text text-danger" style={{fontSize:"30px",fontFamily:"SFProTextSemiBold",margin:"20px 0px 10px 0px"}}>Rp. {rupiah(dshop.shop_price)},-</h4> 
                      <br />
                      <div className='d-flex justify-content-between'>
                        <a target={shoplinktarget} href={shoplink}><i className="fa fa-paper-plane"></i> Preview</a> 
                        <a target="_blank" href={shopinterested}><i className="fab fa-whatsapp"></i> Interested</a> 
                      </div>
                    </div>
                  </div>
                )
              })
              : <div className='alert text-center alert-danger d-block'>Oops, data not found.</div>
            }
            </div>
            <center>
              <a href="javascript:void(0);" limit="6" category="all" className="load_more button-blue-2 mt-4" onClick={loadmoredata}>
                Load More
              </a>
            </center>
        </div>
      </section>
      </div>
  )
}

export default Products;