import { restapilink, baseurl, baseurlebooks } from './Autoload'; 
import axios from 'axios';
import React, { Component, useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
// npm install --save datatables.net-dt 
// npm install datatables.net --save 
import "../../node_modules/datatables.net-dt/js/dataTables.dataTables";
import "../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
const $ = require("jquery");   

function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Ebook(props) { 
  const {category} = useParams();
  const [kategori, setkategori] = useState('');
  const [subtitleebookpage, setsubtitleebookpage] = useState('');
  const [inputsearch, setinputsearch] = useState('');
  const [ebooks, setebooks] = useState([]);
  const [countdataebook, setcountdataebook] = useState(0);
  const [page, setPage] = useState(1);
  const countperpage = 10;
  const navigate = useNavigate();
  const getDataEbook = () => { 
    axios.post(`${restapilink}/api/data/ebook`, {
      page:page,
      countperpage:countperpage,
      keyword:inputsearch,
      kategori:kategori
    }).then(res => {
      setebooks(res.data);
    }).catch(err => {
      setebooks({});
    });
  } 
  const countDataEbook = () => { 
    axios.post(`${restapilink}/api/data/countdataebook`, {
      keyword:inputsearch,
      kategori:kategori
    }).then(res => {
      setcountdataebook(res.data);  
    }).catch(err => {
      setcountdataebook(0);
    });
  }
  const searchHandler = (e) => {
    e.preventDefault();  
    setinputsearch(e.target.value);
    // getDataEbook();  
    // countDataEbook(); 
  }
  const seeallebook = (e) => {
    navigate('/ebook/');
    window.location.reload(); 
  }
  useEffect(() => {
    if(category) {
      setsubtitleebookpage("Ebook Category '"+ucwords(category)+"'");
    }
    else {
      setsubtitleebookpage("All Ebooks");
    }
    if(category) {
      setkategori(category);
      getDataEbook();
      countDataEbook(); 
      $(".seeallebook").css('display','inline-block');
      $(".menu-ebook").addClass(' active ');
    }
    else {
      getDataEbook();
      countDataEbook();
      $(".seeallebook").css('display','none');
    }
    setTimeout(function(){
      $(".menu").removeClass(' active ');
      $(".menu-ebook").addClass(' active ');
      $(".menu-mobile").removeClass(' active ');
      $(".menu-mobile-ebook").addClass(' active ');
    }, 100);
    setTimeout(function(){
      $('#tableebook').DataTable({
        aLengthMenu:[[10,25,50,100,250,500,1000,-1],[10,25,50,100,250,500,1000,'All']]
      }); 
    }, 1500);  
  }, [page, countdataebook, category, kategori]);
  return( 
    <div className="bodypage animated fadeIn ebookpage">
    <title>Berbagi Ebook | Arif Siddik Muharam | Web Developer</title>
    <meta name="title" content="Berbagi Ebook | Arif Siddik Muharam | Web Developer" />
    <meta itemprop="name" content="Berbagi Ebook | Arif Siddik Muharam | Web Developer" />
    <meta property="og:title" content="Berbagi Ebook | Arif Siddik Muharam | Web Developer" />
    <meta name="twitter:title" content="Berbagi Ebook | Arif Siddik Muharam | Web Developer" />
      <section id="latestproject">
          <div className="container">
              <h1 className="content_title">Ebook Sharing</h1>
              <p className='font-regular'>
                  On this page, I share and save several ebooks that I got from various sources.
                  <br/>
                  Support me on <a href="https://saweria.co/arifsiddikm" target="_bl
                  ">https://saweria.co/arifsiddikm</a>.
              </p>
              <hr/>
              <h5 style={{fontFamily: "SFProTextSemiBold"}}>{subtitleebookpage} Available {countdataebook} Data</h5>
              <hr/>
              <div className="table-responsive">
                <table id="tableebook" className="table table-bordered" style={{paddingTop:"16px"}}>
                  <thead>
                      <tr className='bgcolor3 text-white'>
                          <th>Ebook Title</th>
                          <th style={{textAlign:"center",width:"1%"}}>Category</th>
                      </tr>
                  </thead>
                  <tbody>
                  {
                    ebooks.map((ebook, i) => {
                      return (
                        <tr>
                          <td>
                            <a className='linkebookdownlod' target="_blank" href={baseurlebooks+ebook.ebook_filename}>{ucfirst(ebook.ebook_name)}</a>
                          </td>
                          <td style={{textAlign:"center",width:"1%"}}>
                            <a href={baseurl+'ebook/'+strtolower(ebook.ebook_category)+'/'}><small className='badge bgcolor3'>{ucwords(ebook.ebook_category)}</small></a>
                          </td>
                        </tr>
                      )
                    }) 
                  }
                  </tbody>
                </table>
              </div>
            {/* <center>
              <span style={{cursor:"pointer"}} className="seeallebook button-blue-2 mt-4" onClick={seeallebook}>
                See All Ebook
              </span>
            </center> */}
          </div>
      </section>
    </div>
  )
}
export default Ebook;