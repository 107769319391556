import { restapilink, baseurl, baseurlassets, yearnow } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
const $ = require("jquery");   

function About(props) { 
  const [contactarif, setcontactarif] = useState([]); 
  const [datacertificate, setdatacertificate] = useState([]); 
  const [dataexplore, setdataexplore] = useState([]); 
  // getcontactarif 
  const getcontactarif = async () => {
    try {
        let response = await axios.get(restapilink+"api/data/contactarif");
        setcontactarif(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  const getdatacertificate = async () => {
    try {
        let response = await axios.post(restapilink+"api/data/datacertificate");
        setdatacertificate(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  const getdataexplore = async () => {
    try {
        let response = await axios.post(restapilink+"api/data/dataexplore");
        setdataexplore(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  useEffect(() => {
      getcontactarif();
      getdatacertificate();
      getdataexplore();
      setTimeout(function(){
        $(".menu").removeClass(' active ');
        $(".menu-about").addClass(' active ');
        $(".menu-mobile").removeClass(' active ');
        $(".menu-mobile-about").addClass(' active ');
      }, 100);
  }, []); 
  let linkdownloadcv = baseurl + contactarif.contact_cv;
  var fotoarif = baseurlassets+"image/fotoarif7.jpg";
  
  return(
    <div className="bodypage animated fadeIn aboutpage">
    <title>Tentang Saya | Arif Siddik Muharam | Web Developer</title>
    <meta name="title" content="Tentang Saya | Arif Siddik Muharam | Web Developer" />
    <meta itemprop="name" content="Tentang Saya | Arif Siddik Muharam | Web Developer" />
    <meta property="og:title" content="Tentang Saya | Arif Siddik Muharam | Web Developer" />
    <meta name="twitter:title" content="Tentang Saya | Arif Siddik Muharam | Web Developer" />
      <section id="latestproject">
          <div className="container">
              <center>
                  <div className="image">
                      <img src={fotoarif} alt="Foto-Arif-Siddik-Muharam" />
                  </div>
              </center>
              <h1 className="content_title">About Me</h1>
              <p className="">
                  Hallo semua, <br />
                  perkenalkan nama saya Arif, seorang lulusan SMK jurusan TKJ dan sedang berkuliah jurusan T. Informatika. <br />
                  Saya seorang yang tertarik kepada komputer/bidang IT terutama Web Programming <i className="fa fa-code"></i>, sehingga saya memiliki passion di bidang tersebut. <br />
                  Sudah lebih dari {yearnow - 2019} tahun saya berkecimpung di dunia perkomputeran.
              </p>
              <p className="">
                  Saya berprofesi di bidang IT selama {yearnow - 2020} tahun lebih yang berorientasi di bidang Pengembangan Website.
                  Dan di sisi lain, saya juga melakukan kerja lepas di bidang yang sama.
              </p>
              <span className="hero_download animated fadeInUp delay-1s">
                  <a target="_blank" href={linkdownloadcv}><i className="fa fa-download" aria-hidden="true"></i> Unduh CV</a>  
              </span>
          <h1 className="content_title content_title_dark_mode my-5">
              Work Experience
          </h1>
          <ul className="timeline">
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Full-Stack Web Developer | PT Krakatau Pipe Industries, Cilegon</a><br />
                  <a href="javascript:void(0);" className="timeline-year">Juli 2020 - Sekarang</a>
                  <span className="timeline-desc">
                      Saya memiliki pengalaman bekerja yang pekerjaannya antara lain yaitu, mengembangkan Website Perusahaan, mengelola database Website Perusahaan, Troubleshoting Jaringan & Komputer, sampai Desain Grafis.
                  </span>
              </li> 
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Freelance Web Design & Development</a><br />
                  <a href="javascript:void(0);" className="timeline-year">September 2019 - September 2022</a>
                  <span className="timeline-desc">
                      Dalam pekerjaan ini saya mengerjakan proyek freelance sebanyak lebih dari 25 proyek website, mulai dari Web Landing Page, Web Sekolah, Web Tugas Mahasiswa, Web Marketplace, Web Sistem Informasi, Web Ketenagakerjaan hingga Website Perusahaan.
                  </span>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Magang IT Network Support | PT. Sentra Usahatama Jaya</a><br />
                  <a href="javascript:void(0);" className="timeline-year">Februari 2019 - Mei 2019</a>
                  <span className="timeline-desc">
                      Saya memiliki pengalaman prakerin di bidang IT Support
                      yang pekerjaannya antara lain yaitu Troubleshooting PC,
                      Instalasi PC, Maintenance PC, Troublehooting CCTV,
                      Entry Data, Troubleshooting dan Maintenance Jaringan Komputer.
                  </span> 
              </li>
          </ul>

          <h1 className="content_title my-5">
              Education
          </h1>
          <ul className="timeline">
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Universitas Al-Khairiyah Cilegon</a><br />
                  <a href="javascript:void(0);" className="timeline-year">2022 - {yearnow - 2022 == 2026 ? "2026" : "Now"}</a>
                  <span className="timeline-desc">
                      Teknik Informatika
                  </span>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">SMK YP Fatahillah 2 Cilegon</a><br />
                  <a href="javascript:void(0);" className="timeline-year">2017 - 2020</a>
                  <span className="timeline-desc">
                      Teknik Komputer & Jaringan
                  </span>
              </li>
          </ul>
          <h1 className="content_title content_title_dark_mode my-5">
              Skills
          </h1>
          <ul className="timeline ">
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Web Design & Development</a><br />
                  <span className="timeline-desc">Mampu membuat dan mengelola sebuah halaman web menggunakan beberapa bahasa pemrograman serta bisa menggunakan beberapa Framework dan Library tertentu dengan tujuan membuat sebuah website yang bisa berguna untuk tugas sekolah, usaha, pribadi, hingga website perusahaan.</span>
                  <ul className="timeline-list">
                      <li className="timeline-desc">&nbsp;HTML, CSS, Bootstrap, Javascript, jQuery, AJAX, PHP, CodeIgniter, Database, MySQLi, Git, SEO, WordPress, ReactJS (Pemula) Etc</li>
                  </ul>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">IT Network Support</a><br />
                  <span className="timeline-desc">Mampu membuat simulasi sampai rancang bangun jaringan komputer dalam suatu ruangan skala kecil atau sedang, konfigurasi jaringan komputer menggunakan software dan hardware tertentu. Mampu dalam memperbaiki beberapa kendala yang ada pada software dan hardware tertentu pada komputer.</span>
                  <ul className="timeline-list"> 
                      <li className="timeline-desc">&nbsp;Computer Installation, Network Installation, Server Installation, Computer Repair, Maintenance, MikroTik, PABX </li>
                  </ul>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">UI/UX Design</a><br />
                  <span className="timeline-desc">Untuk bidang ini, saya baru memulai beberapa bulan ini, karena saya lihat bidang ini sangat populer dan saya pun ikut mencobanya. Saya biasa membuat mockup website dan beberapa poster sederhana yang masih perlu banyak belajar.</span>
                  <ul className="timeline-list">
                      <li className="timeline-desc">&nbsp;Adobe XD, UI Design, Prototyping</li>
                  </ul>
              </li>
          </ul>
          <h1 className="content_title content_title_dark_mode my-5">
              Achievement
          </h1>
          <ul className="timeline">
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Juara 3 Lomba Web Desain by Universitas Trilogi</a>
                  <br /><a href="javascript:void(0);" className="timeline-year">Juni 2020</a>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Pelatihan Junior Web Developer SKKNI BPPTIK</a>
                  <br /><a href="javascript:void(0);" className="timeline-year">Juni 2020</a>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Olimpiade Programming Tingkat SMA/SMK Se-Indonesia STMIK Antar Bangsa</a>
                  <br /><a href="javascript:void(0);" className="timeline-year">Maret 2020</a>
              </li>
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Juara 2 Lomba Web Desain IT EXPO UNJ, Jakarta</a>
                  <br /><a href="javascript:void(0);" className="timeline-year">November 2019</a> 
              </li> 
              <li>
                  <a href="javascript:void(0);" className="timeline-title">Juara 2 Lomba Kompetensi Siswa Web Technologies Tingkat Kota, Cilegon </a>
                  <br /><a href="javascript:void(0);" className="timeline-year">April 2019</a>
              </li>
          </ul>
          <h1 className="content_title content_title_dark_mode my-5">
              Explore
          </h1>
          <div className="row justify-content-center" style={{alignItems:"center",textAlign: "center"}}>
            {
                dataexplore.map((explore, i) => {
                    var image = baseurlassets+"/image/about/attachment/"+explore.explore_image;
                    var size = explore.explore_size;
                    return (
                        <div className="col-lg-3 mb-4 col-6" key={i}>
                            <img src={image} alt={image} title={explore.explore_judul} style={{width: size}} />
                        </div>
                    )
                }) 
            }
          </div>
          <h1 className="content_title content_title_dark_mode my-5">
            Certificate
          </h1>
          <div className="row list-certificate justify-content-center" style={{alignItems:"center",textAlign: "center"}}>
              {
                datacertificate.map((certificate, i) => {
                    var image = baseurlassets+"/image/about/certificate/"+certificate.certificate_image;
                    return (
                        <div className="col-lg-4 mb-4 col-6" key={i}>
                            <img src={image} alt={image} title={certificate.certificate_judul} className="w-100 img-thumbnail" />
                        </div>
                    )
                }) 
              }
          </div>
          </div>
      </section>
    </div>
  )
}
export default About;