import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/StyleWeb.css'; 
const $ = require("jquery"); 

function Header(props) {
  $("html, body").animate({ scrollTop: 0 }, 50);
  if(window.location.origin=="http://arifsiddikm.com") {
    window.location.href="https://arifsiddikm.com/";
  } 
//   useEffect(() => {
//     setTimeout(function(){
//         $(".menu").removeClass(' active ');
//         $(".menu-blog").removeClass(' active ');
//     }, 500);
//   }, []);

  return(
        <div id="navbar">  
            <div className="container">
                <div className="menu-mobile">
                  <div>&nbsp;</div>
                  <NavLink href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#MenuMobile" aria-controls="MenuMobile">Menu</NavLink>
                </div>
                <div id="menu" className="menu-desktop">
                    <NavLink className="menu menu-home" id="home" to="/">Home</NavLink>
                    <NavLink className="menu menu-portfolio" id="portfolio" to="/portfolio/">Portfolio</NavLink>
                    <NavLink className="menu menu-blog" id="blog" to="/blog/">Blog</NavLink>
                    <NavLink className="menu menu-shop" id="shop" to="/products/">Products</NavLink>
                    <NavLink className="menu menu-ebook" id="ebook" to="/ebook/">Ebook</NavLink>
                    <NavLink className="menu menu-about" id="about" to="/about/">About</NavLink>
                </div>
            </div>
        </div>
    )
}
export default Header;