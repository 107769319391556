import React, { useEffect, useState } from 'react';
import { restapilink, baseurl, baseurlassets } from './Autoload'; 
import axios from 'axios';
const $ = require("jquery");   

function Portfolio(props) {
  const [dataportfolio, setdataportfolio] = useState([]);
  const [dataportfoliostatus, setdataportfoliostatus] = useState();
  const [countalldataportfolio, setcountalldataportfolio] = useState(0);
  const [countdataportfolio, setcountdataportfolio] = useState(0);
  const [inputsearch, setinputsearch] = useState('');
  const [dataperpage, setdataperpage] = useState(6);
  const [kategori, setkategori] = useState('');

  const getdataportfolio = async () => { 
    try {
      let response = await axios.post(restapilink+`api/data/dataportfolio`, {
        inputsearch: inputsearch,
        kategori: kategori,
        dataperpage: dataperpage
      }); 
      setdataportfolio(response.data);
    }
    catch(e) {console.log(e.message);}
  }

  const getcountdataportfolio = () => { 
    axios.post(`${restapilink}/api/data/countdataportfolio`, {
      inputsearch: inputsearch,
      kategori: kategori,
      dataperpage: dataperpage
    }).then(res => {
      setcountalldataportfolio(res.data);  
    }).catch(err => {
      setcountalldataportfolio(0);
    });
  }
  const getcountalldataportfolio = () => { 
    axios.post(`${restapilink}/api/data/countalldataportfolio`, {
      inputsearch: inputsearch,
      kategori: kategori,
      dataperpage: dataperpage
    }).then(res => {
      setcountdataportfolio(res.data);  
    }).catch(err => {
      setcountdataportfolio(0);
    });
  }

  const getdataportfoliostatus = async () => {
    try {
      let response = await axios.post(restapilink+`api/data/dataportfoliostatus`, {
        inputsearch: inputsearch,
        kategori: kategori,
        dataperpage: dataperpage
      }); 
      setdataportfoliostatus(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  const searchHandler = (e) => {
    e.preventDefault();  
    setinputsearch(e.target.value);
  }
  $(".box_search input").on('focus',function(){
    $(".box_search").css('border','2px solid #007bff');
  }) 
  $(".box_search input").on('focusout',function(){
    $(".box_search").css('border','2px solid #e5e5e5');
  })
  const changecategory = (e) => { 
    let kode = e.target.getAttribute('kode') 
    let toclass = e.target.getAttribute('toclass') 
    $(".menu-category").removeClass(" active ")    
    $("."+toclass).addClass(" active ")
    setkategori(kode);  
    setdataperpage(6); 
  }
  
  useEffect(() => {  
    getdataportfolio();  
    getdataportfoliostatus();  
    getcountdataportfolio();  
    getcountalldataportfolio();  
    setTimeout(function(){
      // console.log(countdataportfolio)
      // console.log(countalldataportfolio)
      if(countdataportfolio!==0 && countalldataportfolio!==0) {
        if(countdataportfolio == countalldataportfolio) {
          $(".load_more").css('display','none'); 
        }
        else {
          $(".load_more").css('display','inline-block'); 
        }
      }
      else {
        $(".load_more").css('display','none'); 
      }
    }, 500);
    setTimeout(function(){
      $(".menu").removeClass(' active ');
      $(".menu-portfolio").addClass(' active ');
      $(".menu-mobile").removeClass(' active ');
      $(".menu-mobile-portfolio").addClass(' active ');
    }, 100);
  }, [inputsearch, dataperpage, kategori, countalldataportfolio, countdataportfolio]);

  const loadmoredata = (e) => {
    let newdataperpage = dataperpage + 6;
    setdataperpage(newdataperpage)
    getdataportfoliostatus();  
    getdataportfolio(); 
    getcountdataportfolio();  
    getcountalldataportfolio();  
  }

  return (
    <div className="bodypage animated fadeIn portfoliopage">
    <title>Portofolio Saya | Arif Siddik Muharam | Web Developer</title>
    <meta name="title" content="Portofolio Saya | Arif Siddik Muharam | Web Developer" />
    <meta itemprop="name" content="Portofolio Saya | Arif Siddik Muharam | Web Developer" />
    <meta property="og:title" content="Portofolio Saya | Arif Siddik Muharam | Web Developer" />
    <meta name="twitter:title" content="Portofolio Saya | Arif Siddik Muharam | Web Developer" />

      <section id="latestproject">
        <div className="container">
            <h1 className="content_title">Portfolio</h1>
            <p>
                This is a list of my personal portfolio, I have created something with my knowledge and experience, and I will always develop my skills every day.
            </p>
            <div className="box_search">  
                <i className="fa fa-search"></i> 
                <input type="text" className="input_search" placeholder="Search keyword..." onChange={searchHandler} value={inputsearch} /> 
            </div> 
            <div className="list_category_link">
                <a className="menu-category category-all active" href="javascript:void(0);" onClick={changecategory} toclass="category-all" kode="all">All</a>
                <a className="menu-category category-webapps" href="javascript:void(0);" onClick={changecategory} toclass="category-webapps" kode="Web Apps">Web</a>
                <a className="menu-category category-mobileapp" href="javascript:void(0);" onClick={changecategory} toclass="category-mobileapp" kode="Mobile Apps">Mobile</a>
                <a className="menu-category category-tools" href="javascript:void(0);" onClick={changecategory} toclass="category-tools" kode="Tools">Tools</a>
                <a className="menu-category category-design" href="javascript:void(0);" onClick={changecategory} toclass="category-design" kode="Design">Design</a>
            </div>
            <hr />
            <h4 class='font-semibold'>{dataportfoliostatus}</h4>

            <div className="row justify-content-center row-portfolio">
              {
                Array.isArray(dataportfolio) ?
                dataportfolio.map((dportfolio, i) => {
                  var portoimg = baseurlassets+"image/portfolio/thumbnail/"+dportfolio.portfolio_image;  
                  var portfoliopreview = dportfolio.portfolio_link !== "" ? dportfolio.portfolio_link : "javascript:void(0);"; 
                  var portfoliopreviewtarget = dportfolio.portfolio_link !== "" ? "_blank" : "";
                  return ( 
                    <div className="card col-lg-4 mb-1" key={i}>
                      <div className="image">
                        <img src={portoimg} />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{dportfolio.portfolio_name}</h5>
                        <span className="badge bgcolor3">{dportfolio.portfolio_category}</span>
                        <p className="card-text">{dportfolio.portfolio_desc}</p>
                      </div>
                      <div className="card-footer">
                        <a target={portfoliopreviewtarget} href={portfoliopreview}><i className="fa fa-paper-plane"></i> Preview</a>
                      </div>
                    </div>
                  )
                })
                : <div className='alert text-center alert-danger d-block'>Oops, data not found.</div>
              }
            </div>
            <center>
                <a href="javascript:void(0);" limit="6" category="all" className="load_more button-blue-2 mt-4" onClick={loadmoredata}>
                  Load More
                </a>
            </center>
        </div>
      </section>
    </div>
  )
}

export default Portfolio;