import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import WebArif from './WebArif';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('webarif'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <WebArif />
    </BrowserRouter>
  // </React.StrictMode>
);
reportWebVitals();