import React from 'react';
import './WebArif.css';
import NavbarMobile from './components/NavbarMobile';
import Router from './router';
function WebArif() {
  return (
    <div className="WebArif">
      <Router/>
      <NavbarMobile/>
    </div>
  );
}
export default WebArif;