import { restapilink, baseurl, yearnow, baseurlassets } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
const $ = require("jquery");   

function Home(props) {
  const [latestportoweb, setlatestportoweb] = useState([]);
  const [latestportomobile, setlatestportomobile] = useState([]);
  // getlatestportoweb
  const getlatestportoweb = async () => {
    try {
      let response = await axios.get(restapilink+"api/data/latestportoweb");
      setlatestportoweb(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  let latestportowebimg = latestportoweb.portfolio_image ? baseurlassets+'image/portfolio/thumbnail/' + latestportoweb.portfolio_image : '';
  // getlatestportomobile
  const getlatestportomobile = async () => {
    try {
      let response = await axios.get(restapilink+"api/data/latestportomobile");
      setlatestportomobile(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  let latestportomobileimg = latestportomobile.portfolio_image ? baseurlassets+'image/portfolio/thumbnail/' + latestportomobile.portfolio_image : '';

  const [contactarif, setcontactarif] = useState([]);
  // getcontactarif
  const getcontactarif = async () => {
    try {
      let response = await axios.get(restapilink+"api/data/contactarif");
      setcontactarif(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  useEffect(() => { 
    getlatestportoweb();
    getlatestportomobile();
    getcontactarif();
    setTimeout(function(){
      $(".menu").removeClass(' active ');
      $(".menu-home").addClass(' active ');
      $(".menu-mobile").removeClass(' active ');
      $(".menu-mobile-home").addClass(' active ');
    }, 100);
  }, []);
  let linkdownloadcv = baseurl + contactarif.contact_cv;
  var jumlahtahunarifbekerja = yearnow - 2020;
  var fotoarif = baseurlassets+"image/fotoarif7.jpg";

  return(
    <div className="bodypage animated fadeIn">
    <title>Arif Siddik Muharam | Web Developer</title>
    <meta name="title" content="Arif Siddik Muharam | Web Developer" />
    <meta itemprop="name" content="Arif Siddik Muharam | Web Developer" />
    <meta property="og:title" content="Arif Siddik Muharam | Web Developer" />
    <meta name="twitter:title" content="Arif Siddik Muharam | Web Developer" />

      <div id="hero">
          <div className="container">
              <div id="hero_text">
                  <span className="hero_desc d-block mb-1" >
                      Hello, my name is 
                  </span>
                  <h1 className="hero_title hero_title_dark_mode hero_title_light_mode">
                      Arif Siddik Muharam
                  </h1>
                  <span className="hero_desc d-block">
                      I'm from Indonesia. I have worked as Full Stack Web Developer for more than {jumlahtahunarifbekerja} year. I am also currently opening a Website Development Service & Android WebView Application that is ready to work on your project.
                  </span>
                  <span className="hero_icon">
                      <a href={`${contactarif.contact_github}`} target="_blank"><i className="fab fa-github"></i></a>
                      <a href={`${contactarif.contact_linkedin}`} target="_blank"><i className="fab fa-linkedin"></i></a>
                      <a href={`${contactarif.contact_instagram}`} target="_blank"><i className="fab fa-instagram"></i></a>
                      <a href={`mailto:${contactarif.contact_email}`} target="_blank"><i className="fa fa-envelope"></i></a>
                  </span>
                  <span className="hero_download">
                      <a target="_blank" href={linkdownloadcv}><i className="fa fa-download" aria-hidden="true"></i> Get my CV</a> 
                  </span>
              </div>
              <div id="hero_image">
                  <div className="image">
                      <img src={fotoarif} alt="Foto-Arif-Siddik-Muharam"/>
                  </div>
              </div>
          </div>
      </div>

      <section id="aboutme">
        <div className="container">
            <h1 className="content_title">About Me</h1>
            <p>
                Someone who is interested in IT and has potential in the field of Web Programming. Currently working as IT Staff Web Programmer. <br/>
                I have Freelance experience and working as a Web Developer for more than {yearnow - 2020} year. <br/>
                here's a glimpse of my current experience and education:
            </p>
            <div className="row row-home">
                <div className="col-lg-4 mb-4">
                    <div className="box">
                        <div className="body">
                            <h2 className="title">Full-Stack Web Developer</h2>
                            <h3 className="subtitle textcolor3">PT Krakatau Pipe Industries <br/> 2020 - Now</h3>
                            <p>
                                My scope of work is Web Development, Wordpress, CodeIgniter Framework, Build Material Receive Inspection Report Platform for Company Business, Build a Website Company's Internal Information System Management, etc.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="box">
                        <div className="body">
                            <h2 className="title">Student</h2>  
                            <h3 className="subtitle textcolor3">Universitas Al-Khairiyah Cilegon <br/> 2022 - {yearnow - 2022 == 2026 ? "2026" : "Now"}</h3>
                            <p>
                                At the College education level, I took the Informatich Engineer study program.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4"> 
                    <NavLink className="box viewother menu-about2" id="about" to="/about/">
                        View More &nbsp;<i className="fa fa-arrow-right"></i>
                    </NavLink>
                </div>
            </div>
        </div>
      </section>
      <section id="latestproject">
        <div className="container">
            <h1 className="content_title">Latest Project</h1>
            <p>
                This is a list of my personal portfolio, I have created something with my knowledge and experience, and I will always develop my skills every day.
            </p>
            <div className="row row-portfolio">
                <div className="card col-lg-4 mb-1">
                    <div className="image">
                        <img src={latestportowebimg} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{latestportoweb.portfolio_name}</h5>
                        <span className="badge bgcolor3">{latestportoweb.portfolio_category}</span>
                        <p className="card-text">{latestportoweb.portfolio_desc}</p>
                    </div>
                    <div className="card-footer">
                      <a target="_blank" id="portfolio" className="menu-portfolio2" href={ latestportoweb.portfolio_link ? latestportoweb.portfolio_link : 'javascript:void(0);' }><i className="fa fa-paper-plane"></i> Preview</a> 
                    </div>
                </div>
                <div className="card col-lg-4 mb-1">
                    <div className="image">
                        <img src={latestportomobileimg} />
                    </div> 
                    <div className="card-body">
                        <h5 className="card-title">{latestportomobile.portfolio_name}</h5>
                        <span className="badge bgcolor3">{latestportomobile.portfolio_category}</span>
                        <p className="card-text">{latestportomobile.portfolio_desc}</p>
                    </div>
                    <div className="card-footer">
                      <a target="_blank" id="portfolio" className="menu-portfolio2" href={ latestportomobile.portfolio_link ? latestportomobile.portfolio_link : 'javascript:void(0);' }><i className="fa fa-paper-plane"></i> Preview</a>
                    </div>
                </div> 
                <div className="col-lg-4 mb-1 card">
                    <NavLink className="box viewother menu-portfolio2" id="portfolio" to="/portfolio/">
                        View More &nbsp;<i className="fa fa-arrow-right"></i>
                    </NavLink>
                </div>
            </div>
        </div>
      </section>
      <section id="interestedwithme">
        <div className="container">
            <h1>
                Interested working with me?
            </h1>
            <div>
                <a href="mailto:arifsiddikmuharam@gmail.com" className="btnemailme">
                    Email Me
                </a>
                <NavLink to="/portfolio/" id="portfolio" className="menu-portfolio2 btnemailme">
                    See All Portfolio
                </NavLink>
            </div>        
        </div>
      </section>

    </div>
  )
}

export default Home;