import React from 'react';
import { NavLink } from 'react-router-dom';
const $ = require("jquery");   
function NavbarMobile(props) {
    const closemenu = (e) => {  
        // console.log('menu diklik')
        $(".offcanvas .btn-close").click();
    }
    return(
        <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="MenuMobile" aria-labelledby="MenuMobileLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="MenuMobileLabel"></h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className="listmenumobile">
                    <NavLink className="menu-mobile menu-mobile-home" activeClassName="active" onClick={closemenu} to="/">Home</NavLink>
                    <NavLink className="menu-mobile menu-mobile-portfolio" activeClassName="active" onClick={closemenu} to="/portfolio/">Portfolio</NavLink>
                    <NavLink className="menu-mobile menu-mobile-blog" activeClassName="active" onClick={closemenu} to="/blog/">Blog</NavLink>
                    <NavLink className="menu-mobile menu-mobile-shop" activeClassName="active" onClick={closemenu} to="/products/">Products</NavLink>
                    <NavLink className="menu-mobile menu-mobile-ebook" activeClassName="active" onClick={closemenu} to="/ebook/">Ebook</NavLink>
                    <NavLink className="menu-mobile menu-mobile-about" activeClassName="active" onClick={closemenu} to="/about/">About</NavLink>
                </div>
            </div>
        </div>
    )
}
export default NavbarMobile;