import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Portfolio from '../components/Portfolio';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../components/Home';
import Blog from '../components/Blog';
import BlogDetail from '../components/BlogDetail';
import Products from '../components/Products';
import Ebook from '../components/Ebook';
import About from '../components/About';
import NotFound from '../components/NotFound';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsConditions from '../components/TermsConditions';
function Router(props) {
return(
<Routes>
    <Route path='/' element={<><Header/><Home/><Footer/></>} />
    <Route path='/portfolio' element={<><Header/><Portfolio/><Footer/></>} />
    <Route path='/blog' element={<><Header/><Blog/><Footer/></>} />
    <Route path='/products' element={<><Header/><Products/><Footer/></>} />
    <Route path='/ebook' element={<><Header/><Ebook/><Footer/></>} />
    <Route path='/ebook/:category' element={<><Header/><Ebook/><Footer/></>} />
    <Route path='/about' element={<><Header/><About/><Footer/></>} />
    <Route path='/blog/:uri' element={<><Header/><BlogDetail/><Footer/></>} />
    <Route path='/privacy_policy' element={<><Header/><PrivacyPolicy/><Footer/></>}/>
    <Route path='/terms_conditions' element={<><Header/><TermsConditions/><Footer/></>}/>
    <Route path='*' element={<NotFound/>} />
</Routes>
)
}
export default Router;