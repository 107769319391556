import DOMPurify from 'dompurify'
import { restapilink, baseurl, baseurlassets } from './Autoload'; 
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
const $ = require("jquery");  
const nl2br = require('react-nl2br');

function ucwords (str) { 
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
} 
function strtolower (str) {
  return (str+'').toLowerCase();
} 
function ucfirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function BlogDetail(props) {
  const {uri} = useParams();
  const [datablog, setdatablog] = useState([]);
  const getdatablog = async () => { 
    try {
      let response = await axios.post(restapilink+`api/data/datablog`, {
        uri: uri
      });
      setdatablog(response.data);
    }
    catch(e) {console.log(e.message);}
  }
  useEffect(() => {
    getdatablog();  

    setTimeout(function(){
      $(".menu").removeClass(' active ');
      $(".menu-blog").addClass(' active ');
      $(".menu-mobile").removeClass(' active ');
      $(".menu-mobile-blog").addClass(' active ');
    }, 100);

    setTimeout(function() {
      $(".blog-desc img").attr('style','width:100% !important;height:auto !important;border-radius:12px !important;');  
      $(".blog-desc a").attr("target","_blank");  
    }, 1000);
    
  }, [uri]);

  

  if(Array.isArray(datablog)) {
    return(
      <div className="bodypage animated fadeIn blogdetailpage">
          <section id="latestproject">
              {
                  Array.isArray(datablog) ?
                  datablog.map((dblog, i) => { 
                      var blogthumbnail = baseurlassets+"image/blog/thumbnail/"+dblog.blog_thumbnail;
                      var bloguri = baseurl + 'blog/' + dblog.blog_uri;
                      var blogjudul = ucfirst(dblog.blog_judul);  
                      var blogdesc = nl2br(dblog.blog_desc);  
                      var blogdesc2 = dblog.blog_desc;
                      // var blogdesc2x = dblog.blog_desc;
                      // var blogdesc2 = blogdesc2x.replace("ckfinder/userfiles/files","webarif1.arifsiddikm.com/ckfinder/userfiles/files")
                      var blogpreview = dblog.blog_link !== "" ? dblog.blog_link : "javascript:void(0);"; 
                      var blogpreviewtarget = dblog.blog_link !== "" ? "_blank" : "";

                      var category = "";
                      var pecahcategory = dblog.blog_category.split(",");
                      for (let i = 0; i < pecahcategory.length; i++) {
                          var cat = pecahcategory[i].trim();
                          if(cat!=="") {
                              category += `<span class="badge bgcolor3"><i class="fa fa-tag"></i> `+ucwords(cat)+`</span>&nbsp;`;
                          }
                      }
                      return (
                          <div className="container" key={i}>
                            <title>{blogjudul} | Blog Arif Siddik Muharam | Web Developer</title>
                            <meta name="title" content="Blog | Arif Siddik Muharam | Web Developer" />
                            <meta itemprop="name" content="Blog | Arif Siddik Muharam | Web Developer" />
                            <meta property="og:title" content="Blog | Arif Siddik Muharam | Web Developer" />
                            <meta name="twitter:title" content="Blog | Arif Siddik Muharam | Web Developer" />
                            <h1 className="content_title">{blogjudul}</h1>
                            <span className="badge bgcolor3"><i className="fa fa-calendar"></i> {dblog.blog_tanggal}</span>
                            &nbsp;
                            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(category)}}></span>
                            <br />
                            <div className="row justify-content-center row-detail-blog">
                                <div className="blog-thumbnail">
                                    <img src={blogthumbnail} alt={blogthumbnail} />
                                </div>
                                <div className="blog-desc"> 
                                  <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(blogdesc2)}}></span>
                                </div>
                            </div>
                            <br />
                            <br />
                            <NavLink to="/blog/" className="load_more button-blue-2 mt-4 d-inline-block m-0">
                                <i className="fa fa-arrow-circle-left"></i> &nbsp;Back
                            </NavLink>
                          </div>
                      )
                  })
                  : <div className='alert w-50 m-auto text-center alert-danger d-block'>Oops, data not found.</div>
              }
          </section>
      </div>
    )
  }
  else {
    document.location.href= baseurl+'blog/' ;
  }
}
export default BlogDetail;