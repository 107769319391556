const d = new Date(); 
d.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
let hh = d.getHours();      
let mm = d.getMinutes();  
let ss = d.getSeconds(); 
let session = "AM";   
if(hh == 0){hh = 12;} 
if(hh > 12){hh = hh - 12;session = "PM";}
hh = (hh < 10) ? "0" + hh : hh; 
mm = (mm < 10) ? "0" + mm : mm;   
ss = (ss < 10) ? "0" + ss : ss; 
// var monthnow = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
const lastyear = d.getFullYear() - 1;  
const yearnow = d.getFullYear();         
var lastmonth0 = d.getMonth(); // 👈️ months are 0-based
var last2month0 = d.getMonth() - 1; // 👈️ months are 0-based
var last3month0 = d.getMonth() - 3; // 👈️ months are 0-based
const monthnow0 = d.getMonth() + 1; // 👈️ months are 0-based
function getDaysInMonth(year, month) {    
  return new Date(year, month, 0).getDate();  
}
const daysInCurrentMonth = getDaysInMonth(yearnow, monthnow0); 
var datenow = String(d.getDate()).padStart(2, '0'); 
var todaydate = yearnow + '-' + monthnow0 + '-' + datenow; 
var dateinserted = yearnow + '-' + monthnow0 + '-' + datenow;
var todaydate2 = yearnow + '-' + monthnow0 + '-'; 
if(monthnow0 < 10) { var monthnow = "0"+monthnow0;}  
else {var monthnow = monthnow0;}

// const restapilink = "http://localhost/Project/restapiarif2.arifsiddikm.com/";
const restapilink = "https://restapiarif2.arifsiddikm.com/";

// const baseurl = "http://localhost:3000/"; 
const baseurl = "https://arifsiddikm.com/";
// const baseurl = "https://localhost:3000/";
// const baseurl = "https://arifsiddikm.com/";  

const baseurlassets = "https://assetsarif2.arifsiddikm.com/";
const baseurlebooks = "https://ebooks.arifsiddikm.com/";

// const baseurl2 = "localhost:3000";
const baseurl2 = "arifsiddikm.com";
const baseurl3 = "arifsiddikm.com/";

module.exports = { restapilink, baseurl, baseurl2, baseurlassets, baseurlebooks, yearnow, monthnow, datenow }