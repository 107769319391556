import { restapilink, baseurl, yearnow } from './Autoload'; 
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function Footer(props) {
    const [contactarif, setcontactarif] = useState([]);
    // getcontactarif
    const getcontactarif = async () => {
    try {
        let response = await axios.get(restapilink+"api/data/contactarif");
        setcontactarif(response.data);
    }
    catch(e) {console.log(e.message);}
    }
    useEffect(() => {
        getcontactarif();
    }, []);
    return(
        <div className="footer">
            <div className="container">
                <a target="_blank" href={`${contactarif.contact_github}`} className="icon-link-footer" title="GitHub"><i className="fab fa-github"></i></a>  
                <a target="_blank" href={`${contactarif.contact_linkedin}`} className="icon-link-footer" title="LinkedIn"><i className="fab fa-linkedin"></i></a>  
                <a target="_blank" href={`${contactarif.contact_instagram}`} className="icon-link-footer" title="Instagram"><i className="fab fa-instagram"></i></a>  
                <a target="_blank" href={`${contactarif.contact_whatsapp}`} className="icon-link-footer" title="Whatsapp"><i className="fab fa-whatsapp"></i></a>  
                <a href={`mailto:${contactarif.contact_email}`} target="_blank" className="icon-link-footer"><i className="fa fa-envelope"></i></a>
                <br/>
                <br/>
                <br/>
                <center> 
                    Build with <span style={{fontSize:"13px"}}>❤️</span> by <a href={`${contactarif.contact_instagram}`} target="_blank" >Arif</a>
                    <br/>
                    © 2020 - {yearnow}
                </center>
            </div>
        </div>
    )
}

export default Footer;